@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,600");

/* Viewport Meta Tag */
meta[name="viewport"] {
  width: device-width;
  initial-scale: 1;
  maximum-scale: 1;
  user-scalable: no;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background: #000000;
}

.more-pens {
  position: fixed;
  left: 5%;
  bottom: 5%;
  z-index: 10;
  font-size: 10px;
}

a.white-mode {
  font-size: 10px;
  text-decoration: none;
  background: #212121;
  padding: 2px 4px;
  color: #f7f7f7;
}
a.white-mode:hover {
  background: #edf3f8;
  color: #212121;
}

.title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  max-width: 360px; /* Adjusted max-width */
  margin: 0 auto;
}
.title h1 {
  color: gold;
  font-weight: 100;
  font-size: 8vw;
  line-height: 1;
  text-shadow: 0 0 2vw #ff9900, 0 0 4vw #ff9900, 0 0 6vw #ff9900, 0 0 8vw #ff9900, 0 0 14vw #ff9900, 0 0 16vw #ff9900, 0 0 20vw #ff9900, 0 0 30vw #ff9900;
}
.title h1 span {
  font-weight: 600;
  color: #ff9900;
}
.title h3,
.title h2 {
  font-weight: 200;
  font-size: 6vw;
  line-height: 1;
  color: #ff9900;
  letter-spacing: 0.1em;
}
